@import 'styles/groenlinks.css';
@import 'styles/overwrites.css';

label {
    font-weight: bold;
}

.party-buttons {
    position: sticky;
    top: 5em;
    z-index: 100;
    margin-top: 2em;
    text-align: center;
}

.source {
    margin-bottom: 2em;
}

.source button {
    float: right;
    font-weight: bold;
}

.source-bar {
    -moz-transition: height .4s ease;
    -webkit-transition: height .4s ease;
    -o-transition: height .4s ease;
    transition: height .4s ease;
}

.source-bar-closed .twitter-wrapper {
    display: none;
}

.ggz-notice {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
}
.ggz-notice a {
    color: #39a935;
    text-decoration: underline;
}

.twitter-wrapper > div {
    margin: auto;
}

.partial-notice {
    background: #dddddd;
    color: #4b4b4b;
    padding: 4px 10px;
}
